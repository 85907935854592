export default {
  features: {
    addressFromLocalStorageEnabled: false,
    addressValidationInRegistrationEnabled: true,

    // TODO():, get rid of theese agreements
    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: false,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: false,
    agreementsSmsChannel: ['EMAIL', 'SMS'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: true,
    birthDateEnabled: true,
    birthDateOverEnabled: 16,
    birthDateRequired: true,
    birthDateValidateOnSubmit: false,

    companyDataPrefillEnabled: true,
    companyItInvoicingEnabled: false,

    competitionCouponLimit: 20,
    competitionImagePath: '/images/competition/cz-competition.png',
    competitionNoticeEnabled: false,

    correspondenceEmailEnabled: false,

    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: true,
    discountEnabled: true,

    /*eslint-disable */
    favoriteFilterCategoriesIds: [1271, 1293, 1305, 1445, 1507, 1679, 1915, 2205, 2377, 2859],

    favoritePharmaciesEnabled: true,
    favoriteProductsEnabled: true,

    loyaltyCardBackImagePath: '',
    loyaltyCardCodeLengthCheckEnabled: false,
    loyaltyCardImagePath: '/images/loyalty-card/cz-loyalty-card-with-comments.png',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-red.svg',
    loyaltyCardV2: true,

    cardCodeToOtpModeEnabled: false,
    fiscalCodeEnabled: true,
    loyaltyCardNumberDigits: 9,
    loyaltyCardNumberStaticPrefix: '++',
    loyaltyProgramBenefitsCount: 0,
    myAccountSorpFromCustomerServiceEnabled: false,
    standAloneCardModeEnabled: false,
    mktPpdUnified: true,
    allowEmailChange: false,
    zipCodeRequired: true,
    checkPersonalDetailsCityFieldEnabled: false,
    accountInfoSorpUpgradeEnabled: false,

    phoneNumberRequired: true,
    phoneNumberSorpCheckEnabled: false,
    phoneCodeEnabled: true,

    priceEnabled: false,
    priceSummaryEnabled: true,
    registrationAddressEnabled: true,
    removeUserEnabled: true,
    returnAndComplaintTitleSplitEnabled: false,
    returnIbanFieldEnabled: false,
    showNotificationSettings: true,

    sexEnabled: true,
    sexRequiredEnabled: true,
    sexThirdOptionEnabled: false,
    surveyEnabled: false,
    typingInLoginEmailEnabled: false,
    unitPriceEnabled: false,
    ordersFilterFromEshopEnabled: true,

    emailRestrictedDomainEnabled: false,

    userFillableDetails: {
      allergies: false,
      breastFeeding: false,
      height: false,
      pregnant: false,
      treatments: false,
      weight: false,
    },
  },
}
