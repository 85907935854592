import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _02440514 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/akcni-vyprodej/index.vue' /* webpackChunkName: "pages/akcni-vyprodej" */))
const _ed10c186 = () => interopDefault(import('../app/common/modules/articles/pages/authors/index.vue' /* webpackChunkName: "pages/authors/index" */))
const _3aef5743 = () => interopDefault(import('../app/common/modules/brands/pages/brand-preview.vue' /* webpackChunkName: "pages/brand-preview" */))
const _0f680562 = () => interopDefault(import('../app/common/modules/cms/pages/cms-entity-preview.vue' /* webpackChunkName: "pages/cms-entity-preview" */))
const _b30644fe = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/index.vue' /* webpackChunkName: "pages/pharmacies/index" */))
const _d73fc74a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account.vue' /* webpackChunkName: "pages/my-account" */))
const _2c8e215c = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/baby-club/index.vue' /* webpackChunkName: "pages/my-account/baby-club/index" */))
const _524d1a22 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-profile/index.vue' /* webpackChunkName: "pages/my-account/my-profile/index" */))
const _48aca5b2 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-offers/index.vue' /* webpackChunkName: "pages/my-account/my-offers/index" */))
const _4e3714f5 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-orders/index.vue' /* webpackChunkName: "pages/my-account/my-orders/index" */))
const _29f1235d = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-favorites/index.vue' /* webpackChunkName: "pages/my-account/my-favorites/index" */))
const _29fe43b2 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-alerts/index.vue' /* webpackChunkName: "pages/my-account/my-alerts/index" */))
const _47029ac4 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/index.vue' /* webpackChunkName: "pages/my-account/index" */))
const _382edd67 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/index.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/index" */))
const _f43f25fc = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/return/_id/index.vue' /* webpackChunkName: "pages/my-account/return/_id/index" */))
const _10761d90 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return" */))
const _1327d364 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return/step1.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return/step1" */))
const _130ba462 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return/step2.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return/step2" */))
const _12ef7560 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return/step3.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return/step3" */))
const _5fee15ed = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return/step3-logistic.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return/step3-logistic" */))
const _5dabcd6a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public.vue' /* webpackChunkName: "pages/my-account-public" */))
const _5977caa0 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/email-verification.vue' /* webpackChunkName: "pages/my-account-public/email-verification" */))
const _13dc0304 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/logout.vue' /* webpackChunkName: "pages/my-account-public/logout" */))
const _3208a5a6 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration.vue' /* webpackChunkName: "pages/my-account-public/registration" */))
const _1a7d72fa = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration/step1.vue' /* webpackChunkName: "pages/my-account-public/registration/step1" */))
const _1a6143f8 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration/step2.vue' /* webpackChunkName: "pages/my-account-public/registration/step2" */))
const _1a4514f6 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration/step3.vue' /* webpackChunkName: "pages/my-account-public/registration/step3" */))
const _1a28e5f4 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration/step4.vue' /* webpackChunkName: "pages/my-account-public/registration/step4" */))
const _2706bb8a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/set-token.vue' /* webpackChunkName: "pages/my-account-public/set-token" */))
const _54e00093 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/favorites.vue' /* webpackChunkName: "pages/my-account-public/favorites" */))
const _fb1377fc = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step2.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step2" */))
const _8fe5d966 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step3-personal-data.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step3-personal-data" */))
const _1e2c9f6a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step3-pin.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step3-pin" */))
const _fadb19f8 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step4.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step4" */))
const _00037292 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/email-communication-unsubscription.vue' /* webpackChunkName: "pages/my-account-public/email-communication-unsubscription" */))
const _55b3630a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/amazon/index.vue' /* webpackChunkName: "pages/my-account-public/amazon/index" */))
const _4b609db0 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/unsubscription-confirmation.vue' /* webpackChunkName: "pages/my-account-public/unsubscription-confirmation" */))
const _1f94193e = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/competition/index.vue' /* webpackChunkName: "pages/my-account-public/competition/index" */))
const _fabeeaf6 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step5.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step5" */))
const _2a674d28 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/first-login.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/first-login" */))
const _fb2fa6fe = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step1.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step1" */))
const _3facf496 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/choose-card-type.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/choose-card-type" */))
const _3131e5b9 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/competition/thankyou.vue' /* webpackChunkName: "pages/my-account-public/competition/thankyou" */))
const _0e064586 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/new-password/_hash/index.vue' /* webpackChunkName: "pages/my-account-public/new-password/_hash/index" */))
const _628b78cd = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/_email-confirmation-token.vue' /* webpackChunkName: "pages/my-account-public/_email-confirmation-token" */))
const _d548c0e8 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp.vue' /* webpackChunkName: "pages/my-account-public-sorp" */))
const _a977cee8 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp/registration.vue' /* webpackChunkName: "pages/my-account-public-sorp/registration" */))
const _06192f6f = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp/final-step-loginfailed.vue' /* webpackChunkName: "pages/my-account-public-sorp/final-step-loginfailed" */))
const _5c262549 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp/final-step-not-verified.vue' /* webpackChunkName: "pages/my-account-public-sorp/final-step-not-verified" */))
const _1edb2c0f = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp/final-step-verified.vue' /* webpackChunkName: "pages/my-account-public-sorp/final-step-verified" */))
const _d2d819dc = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/nabidka-1-1-zdarma/index.vue' /* webpackChunkName: "pages/nabidka-1-1-zdarma" */))
const _74790fb6 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/nabidka-3-za-cenu-2/index.vue' /* webpackChunkName: "pages/nabidka-3-za-cenu-2" */))
const _71045823 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/nabidka-s-darkem/index.vue' /* webpackChunkName: "pages/nabidka-s-darkem" */))
const _55a216cc = () => interopDefault(import('../app/common/modules/my-account/pages/new-return.vue' /* webpackChunkName: "pages/new-return" */))
const _d398863c = () => interopDefault(import('../app/common/modules/my-account/pages/new-return/step1.vue' /* webpackChunkName: "pages/new-return/step1" */))
const _d37c573a = () => interopDefault(import('../app/common/modules/my-account/pages/new-return/step2.vue' /* webpackChunkName: "pages/new-return/step2" */))
const _d3602838 = () => interopDefault(import('../app/common/modules/my-account/pages/new-return/step3.vue' /* webpackChunkName: "pages/new-return/step3" */))
const _69d695d9 = () => interopDefault(import('../app/common/modules/my-account/pages/new-return/step3-logistic.vue' /* webpackChunkName: "pages/new-return/step3-logistic" */))
const _6ec5937c = () => interopDefault(import('../layers/subscription/app/pages/newsletter-subscriber-confirm.vue' /* webpackChunkName: "pages/newsletter-subscriber-confirm" */))
const _18439b52 = () => interopDefault(import('../app/common/modules/cms/pages/pagebuilder-preview.vue' /* webpackChunkName: "pages/pagebuilder-preview" */))
const _cac50144 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _30883173 = () => interopDefault(import('../app/common/modules/article-preview/pages/post-preview.vue' /* webpackChunkName: "pages/post-preview" */))
const _27f0e77b = () => interopDefault(import('../app/common/modules/product-preview/pages/product-preview.vue' /* webpackChunkName: "pages/product-preview" */))
const _a3216ef2 = () => interopDefault(import('../app/common/modules/my-account/pages/returns/index.vue' /* webpackChunkName: "pages/returns/index" */))
const _3ca5c49a = () => interopDefault(import('../layers/special-offers/app/pages/special-offers.vue' /* webpackChunkName: "pages/special-offers" */))
const _62f7d557 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/specialni-nabidky/index.vue' /* webpackChunkName: "pages/specialni-nabidky" */))
const _19be287e = () => interopDefault(import('../app/common/modules/tags/pages/tags/index.vue' /* webpackChunkName: "pages/tags/index" */))
const _719dff96 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/vsechny-akce-a-slevy/index.vue' /* webpackChunkName: "pages/vsechny-akce-a-slevy" */))
const _758cfae0 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/index.vue' /* webpackChunkName: "pages/askPharmacist/index" */))
const _926d8ac4 = () => interopDefault(import('../app/common/modules/brands/pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _245cc3ec = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/akce-a-slevy/akce-a-slevy-v-eshopu.vue' /* webpackChunkName: "pages/akce-a-slevy-akce-a-slevy-v-eshopu" */))
const _3d979cd4 = () => interopDefault(import('../app/common/modules/clubs/pages/baby/medicament.vue' /* webpackChunkName: "pages/baby/medicament" */))
const _6d83b008 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/payment-failed.vue' /* webpackChunkName: "pages/checkout/payment-failed" */))
const _1e8c0b67 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/payment-redirect.vue' /* webpackChunkName: "pages/checkout/payment-redirect" */))
const _1bf54b99 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/thankyou.vue' /* webpackChunkName: "pages/checkout/thankyou" */))
const _0d66fd2b = () => interopDefault(import('../app/common/modules/my-account/pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _4910ee9a = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/obecne/ochrana-osobnich-udaju.vue' /* webpackChunkName: "pages/obecne-ochrana-osobnich-udaju" */))
const _5f9d547e = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/obecne/pouzivani-cookies.vue' /* webpackChunkName: "pages/obecne-pouzivani-cookies" */))
const _6b4c1b8b = () => interopDefault(import('../app/common/modules/checkout/pages/order/cancelled.vue' /* webpackChunkName: "pages/order/cancelled" */))
const _17127a5e = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/order/failure.vue' /* webpackChunkName: "pages/order-failure" */))
const _6a2ee917 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/order/payment-failure.vue' /* webpackChunkName: "pages/order-payment-failure" */))
const _4ffda457 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/order/success.vue' /* webpackChunkName: "pages/order-success" */))
const _00816650 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/tiskove-centrum/magazin-maximum.vue' /* webpackChunkName: "pages/tiskove-centrum-magazin-maximum" */))
const _24028d05 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/callCenter.vue' /* webpackChunkName: "pages/askPharmacist/callCenter" */))
const _c634f154 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/questionList.vue' /* webpackChunkName: "pages/askPharmacist/questionList" */))
const _510c7e43 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/pharmacies.vue' /* webpackChunkName: "pages/askPharmacist/pharmacies" */))
const _68ec607c = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/questionForm.vue' /* webpackChunkName: "pages/askPharmacist/questionForm" */))
const _3ef9471f = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/our-dermocenters.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/our-dermocenters" */))
const _74224362 = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/social-networks.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/social-networks" */))
const _6e2afbe7 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _42d40a9f = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/_dermocenter/index.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/_dermocenter/index" */))
const _1ca9310c = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/akcni-vyprodej/[b].vue' /* webpackChunkName: "pages/akcni-vyprodej-b" */))
const _3f61ff56 = () => interopDefault(import('../app/common/modules/articles/pages/authors/_author.vue' /* webpackChunkName: "pages/authors/_author" */))
const _0991542e = () => interopDefault(import('../app/common/modules/my-account/pages/confirmation-email/_token/index.vue' /* webpackChunkName: "pages/confirmation-email/_token/index" */))
const _18407904 = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/_pharmacy/index.vue' /* webpackChunkName: "pages/pharmacies/_pharmacy/index" */))
const _0b4bd7d4 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/nabidka-1-1-zdarma/[b].vue' /* webpackChunkName: "pages/nabidka-1-1-zdarma-b" */))
const _18847c2e = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/nabidka-3-za-cenu-2/[b].vue' /* webpackChunkName: "pages/nabidka-3-za-cenu-2-b" */))
const _bddaed32 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/nabidka-s-darkem/[b].vue' /* webpackChunkName: "pages/nabidka-s-darkem-b" */))
const _254a01b7 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/_step.vue' /* webpackChunkName: "pages/checkout/_step" */))
const _040cbcae = () => interopDefault(import('../app/common/modules/checkout/pages/repay/_hash.vue' /* webpackChunkName: "pages/repay/_hash" */))
const _046bc39b = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/specialni-nabidky/[b].vue' /* webpackChunkName: "pages/specialni-nabidky-b" */))
const _5092b942 = () => interopDefault(import('../app/common/modules/tags/pages/tags/_tag.vue' /* webpackChunkName: "pages/tags/_tag" */))
const _6fe3e40e = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/vsechny-akce-a-slevy/[b].vue' /* webpackChunkName: "pages/vsechny-akce-a-slevy-b" */))
const _229fd283 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/_question.vue' /* webpackChunkName: "pages/askPharmacist/_question" */))
const _07a5214e = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/akcni-vyprodej/index.vue' /* webpackChunkName: "pages/a-akcni-vyprodej" */))
const _46152a2c = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/nabidka-1-1-zdarma/index.vue' /* webpackChunkName: "pages/a-nabidka-1-1-zdarma" */))
const _4b90fb4d = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/nabidka-3-za-cenu-2/index.vue' /* webpackChunkName: "pages/a-nabidka-3-za-cenu-2" */))
const _6491e5fb = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/nabidka-s-darkem/index.vue' /* webpackChunkName: "pages/a-nabidka-s-darkem" */))
const _3dc7fb02 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/specialni-nabidky/index.vue' /* webpackChunkName: "pages/a-specialni-nabidky" */))
const _09d83be6 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/vsechny-akce-a-slevy/index.vue' /* webpackChunkName: "pages/a-vsechny-akce-a-slevy" */))
const _7fe3d023 = () => interopDefault(import('../app/common/modules/catalog/pages/promotion.vue' /* webpackChunkName: "pages/promotion" */))
const _be9fffc4 = () => interopDefault(import('../app/common/modules/search/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _1d19a152 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/akcni-vyprodej/[b].vue' /* webpackChunkName: "pages/a-akcni-vyprodej-b" */))
const _2430bc24 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/nabidka-1-1-zdarma/[b].vue' /* webpackChunkName: "pages/a-nabidka-1-1-zdarma-b" */))
const _71e1ef11 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/nabidka-3-za-cenu-2/[b].vue' /* webpackChunkName: "pages/a-nabidka-3-za-cenu-2-b" */))
const _ac4ca582 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/nabidka-s-darkem/[b].vue' /* webpackChunkName: "pages/a-nabidka-s-darkem-b" */))
const _14891ac3 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/specialni-nabidky/[b].vue' /* webpackChunkName: "pages/a-specialni-nabidky-b" */))
const _0e6f85d1 = () => interopDefault(import('../layers/multi-app/layers/cz-drmax/app/pages/[a]/vsechny-akce-a-slevy/[b].vue' /* webpackChunkName: "pages/a-vsechny-akce-a-slevy-b" */))
const _242aae65 = () => interopDefault(import('../app/common/modules/base/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'isCurrent',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/akce-a-slevy",
    redirect: "/akce-a-slevy/akce-a-slevy-v-eshopu"
  }, {
    path: "/akcni-vyprodej",
    component: _02440514,
    name: "akcni-vyprodej___cz",
    children: []
  }, {
    path: "/autori",
    component: _ed10c186,
    name: "authors___cz"
  }, {
    path: "/brand-preview",
    component: _3aef5743,
    name: "brand-preview___cz"
  }, {
    path: "/cms-entity-preview",
    component: _0f680562,
    name: "cms-entity-preview___cz"
  }, {
    path: "/lekarny",
    component: _b30644fe,
    name: "pharmacies___cz"
  }, {
    path: "/my-account",
    component: _d73fc74a,
    children: [{
      path: "/kartavyhod/baby-club",
      component: _2c8e215c,
      name: "my-account-baby-club___cz"
    }, {
      path: "/kartavyhod/editace-udaju",
      component: _524d1a22,
      name: "my-account-my-profile___cz"
    }, {
      path: "/kartavyhod/moje-nabidky",
      component: _48aca5b2,
      name: "my-account-my-offers___cz"
    }, {
      path: "/kartavyhod/moje-objednavky",
      component: _4e3714f5,
      name: "my-account-my-orders___cz"
    }, {
      path: "/kartavyhod/moje-oblibene",
      component: _29f1235d,
      name: "my-account-my-favorites___cz"
    }, {
      path: "/kartavyhod/moji-hlidaci-psi",
      component: _29fe43b2,
      name: "my-account-my-alerts___cz"
    }, {
      path: "/kartavyhod/muj-ucet",
      component: _47029ac4,
      name: "my-account___cz"
    }, {
      path: "/kartavyhod/objednavka/:orderId",
      component: _382edd67,
      name: "my-account-purchase-orderId___cz"
    }, {
      path: "return/:id",
      component: _f43f25fc,
      name: "my-account-return-id___cz"
    }, {
      path: "purchase/:orderId?/new-return",
      component: _10761d90,
      name: "my-account-purchase-orderId-new-return___cz",
      children: [{
        path: "step1",
        component: _1327d364,
        name: "my-account-purchase-orderId-new-return-step1___cz"
      }, {
        path: "step2",
        component: _130ba462,
        name: "my-account-purchase-orderId-new-return-step2___cz"
      }, {
        path: "step3",
        component: _12ef7560,
        name: "my-account-purchase-orderId-new-return-step3___cz"
      }, {
        path: "step3-logistic",
        component: _5fee15ed,
        name: "my-account-purchase-orderId-new-return-step3-logistic___cz"
      }]
    }]
  }, {
    path: "/my-account-public",
    component: _5dabcd6a,
    name: "my-account-public___cz",
    children: [{
      path: "email-verification",
      component: _5977caa0,
      name: "my-account-public-email-verification___cz"
    }, {
      path: "logout",
      component: _13dc0304,
      name: "my-account-public-logout___cz"
    }, {
      path: "registration",
      component: _3208a5a6,
      name: "my-account-public-registration___cz",
      children: [{
        path: "/kartavyhod/registrace/krok-1",
        component: _1a7d72fa,
        name: "my-account-public-registration-step1___cz"
      }, {
        path: "/kartavyhod/registrace/krok-2",
        component: _1a6143f8,
        name: "my-account-public-registration-step2___cz"
      }, {
        path: "/kartavyhod/registrace/krok-3",
        component: _1a4514f6,
        name: "my-account-public-registration-step3___cz"
      }, {
        path: "/kartavyhod/registrace/krok-4",
        component: _1a28e5f4,
        name: "my-account-public-registration-step4___cz"
      }]
    }, {
      path: "/login-from-application",
      component: _2706bb8a,
      name: "my-account-public-set-token___cz"
    }, {
      path: "/oblibene-produkty",
      component: _54e00093,
      name: "my-account-public-favorites___cz"
    }, {
      path: "loyalty-card/step2",
      component: _fb1377fc,
      name: "my-account-public-loyalty-card-step2___cz"
    }, {
      path: "loyalty-card/step3-personal-data",
      component: _8fe5d966,
      name: "my-account-public-loyalty-card-step3-personal-data___cz"
    }, {
      path: "loyalty-card/step3-pin",
      component: _1e2c9f6a,
      name: "my-account-public-loyalty-card-step3-pin___cz"
    }, {
      path: "loyalty-card/step4",
      component: _fadb19f8,
      name: "my-account-public-loyalty-card-step4___cz"
    }, {
      path: "/kartavyhod/emailova-komunikace-odhlaseni",
      component: _00037292,
      name: "my-account-public-email-communication-unsubscription___cz"
    }, {
      path: "/kartavyhod/nabidky-a-kupony",
      component: _55b3630a,
      name: "my-account-public-amazon___cz"
    }, {
      path: "/kartavyhod/potvrzeni-odhlaseni",
      component: _4b609db0,
      name: "my-account-public-unsubscription-confirmation___cz"
    }, {
      path: "/kartavyhod/soutez",
      component: _1f94193e,
      name: "my-account-public-competition___cz"
    }, {
      path: "/kartavyhod/registrace/krok-5",
      component: _fabeeaf6,
      name: "my-account-public-loyalty-card-step5___cz"
    }, {
      path: "/kartavyhod/registrace/prvni-prihlaseni",
      component: _2a674d28,
      name: "my-account-public-loyalty-card-first-login___cz"
    }, {
      path: "/kartavyhod/registrace/uvod",
      component: _fb2fa6fe,
      name: "my-account-public-loyalty-card-step1___cz"
    }, {
      path: "/kartavyhod/registrace/vyberte-typ",
      component: _3facf496,
      name: "my-account-public-loyalty-card-choose-card-type___cz"
    }, {
      path: "/kartavyhod/soutez/dekujeme",
      component: _3131e5b9,
      name: "my-account-public-competition-thankyou___cz"
    }, {
      path: "/kartavyhod/nove-heslo/:hash",
      component: _0e064586,
      name: "my-account-public-new-password-hash___cz"
    }, {
      path: "/kartavyhod/overeni/:token",
      component: _628b78cd,
      name: "my-account-public-email-confirmation-token___cz"
    }]
  }, {
    path: "/my-account-public-sorp",
    component: _d548c0e8,
    name: "my-account-public-sorp___cz",
    children: [{
      path: "/kartavyhod/registrace",
      component: _a977cee8,
      name: "my-account-public-sorp-registration___cz"
    }, {
      path: "/my-account/final-step-loginfailed",
      component: _06192f6f,
      name: "my-account-public-sorp-final-step-loginfailed___cz"
    }, {
      path: "/my-account/final-step-not-verified",
      component: _5c262549,
      name: "my-account-public-sorp-final-step-not-verified___cz"
    }, {
      path: "/my-account/final-step-verified",
      component: _1edb2c0f,
      name: "my-account-public-sorp-final-step-verified___cz"
    }]
  }, {
    path: "/nabidka-1-1-zdarma",
    component: _d2d819dc,
    name: "nabidka-1-1-zdarma___cz",
    children: []
  }, {
    path: "/nabidka-3-za-cenu-2",
    component: _74790fb6,
    name: "nabidka-3-za-cenu-2___cz",
    children: []
  }, {
    path: "/nabidka-s-darkem",
    component: _71045823,
    name: "nabidka-s-darkem___cz",
    children: []
  }, {
    path: "/new-return",
    component: _55a216cc,
    name: "new-return___cz",
    children: [{
      path: "step1",
      component: _d398863c,
      name: "new-return-step1___cz"
    }, {
      path: "step2",
      component: _d37c573a,
      name: "new-return-step2___cz"
    }, {
      path: "step3",
      component: _d3602838,
      name: "new-return-step3___cz"
    }, {
      path: "step3-logistic",
      component: _69d695d9,
      name: "new-return-step3-logistic___cz"
    }]
  }, {
    path: "/newsletter-subscriber-confirm",
    component: _6ec5937c,
    name: "newsletter-subscriber-confirm___cz",
    children: []
  }, {
    path: "/pagebuilder-preview",
    component: _18439b52,
    name: "pagebuilder-preview___cz"
  }, {
    path: "/pokladna",
    component: _cac50144,
    alias: "/checkout",
    name: "checkout___cz"
  }, {
    path: "/post-preview",
    component: _30883173,
    name: "post-preview___cz"
  }, {
    path: "/product-preview",
    component: _27f0e77b,
    name: "product-preview___cz"
  }, {
    path: "/returns",
    component: _a3216ef2,
    name: "returns___cz"
  }, {
    path: "/special-offers",
    component: _3ca5c49a,
    name: "special-offers___cz",
    children: []
  }, {
    path: "/specialni-nabidky",
    component: _62f7d557,
    name: "specialni-nabidky___cz",
    children: []
  }, {
    path: "/tagy",
    component: _19be287e,
    name: "tags___cz"
  }, {
    path: "/vsechny-akce-a-slevy",
    component: _719dff96,
    name: "vsechny-akce-a-slevy___cz",
    children: []
  }, {
    path: "/zeptejte-se-lekarnika",
    component: _758cfae0,
    name: "askPharmacist___cz"
  }, {
    path: "/znacky",
    component: _926d8ac4,
    name: "brands___cz"
  }, {
    path: "/akce-a-slevy/akce-a-slevy-v-eshopu",
    component: _245cc3ec,
    name: "akce-a-slevy-akce-a-slevy-v-eshopu___cz",
    children: []
  }, {
    path: "/akce/akce-a-slevy-v-eshopu",
    redirect: "/akce-a-slevy/akce-a-slevy-v-eshopu"
  }, {
    path: "/akce/akcni-vyprodej",
    redirect: "/akcni-vyprodej"
  }, {
    path: "/akce/nabidka-1-1-zdarma",
    redirect: "/nabidka-1-1-zdarma"
  }, {
    path: "/akce/nabidka-3-za-cenu-2",
    redirect: "/nabidka-3-za-cenu-2"
  }, {
    path: "/akce/nabidka-s-darkem",
    redirect: "/nabidka-s-darkem"
  }, {
    path: "/akce/specialni-nabidky",
    redirect: "/specialni-nabidky"
  }, {
    path: "/akce/vsechny-akce-a-slevy",
    redirect: "/vsechny-akce-a-slevy"
  }, {
    path: "/baby/overeni-leku-v-tehotenstvi",
    component: _3d979cd4,
    name: "baby-medicament___cz"
  }, {
    path: "/checkout/payment-failed",
    component: _6d83b008,
    name: "checkout-payment-failed___cz"
  }, {
    path: "/checkout/payment-redirect",
    component: _1e8c0b67,
    name: "checkout-payment-redirect___cz"
  }, {
    path: "/checkout/thankyou",
    component: _1bf54b99,
    name: "checkout-thankyou___cz"
  }, {
    path: "/kartavyhod/obnoveni-hesla",
    component: _0d66fd2b,
    name: "reset-password___cz"
  }, {
    path: "/obecne/ochrana-osobnich-udaju",
    component: _4910ee9a,
    name: "obecne-ochrana-osobnich-udaju___cz",
    children: []
  }, {
    path: "/obecne/pouzivani-cookies",
    component: _5f9d547e,
    name: "obecne-pouzivani-cookies___cz",
    children: []
  }, {
    path: "/order/cancelled",
    component: _6b4c1b8b,
    name: "order-cancelled___cz"
  }, {
    path: "/order/failure",
    component: _17127a5e,
    name: "order-failure___cz",
    children: []
  }, {
    path: "/order/payment-failure",
    component: _6a2ee917,
    name: "order-payment-failure___cz",
    children: []
  }, {
    path: "/order/success",
    component: _4ffda457,
    name: "order-success___cz",
    children: []
  }, {
    path: "/tiskove-centrum/magazin-maximum",
    component: _00816650,
    name: "tiskove-centrum-magazin-maximum___cz",
    children: []
  }, {
    path: "/zeptejte-se-lekarnika/call-centrum",
    component: _24028d05,
    name: "askPharmacist-callCenter___cz"
  }, {
    path: "/zeptejte-se-lekarnika/dotazy",
    component: _c634f154,
    name: "askPharmacist-questionList___cz"
  }, {
    path: "/zeptejte-se-lekarnika/lekarny",
    component: _510c7e43,
    name: "askPharmacist-pharmacies___cz"
  }, {
    path: "/zeptejte-se-lekarnika/polozit-dotaz",
    component: _68ec607c,
    name: "askPharmacist-questionForm___cz"
  }, {
    path: "/lekarny/dermocentra/nase-dermocentra",
    component: _3ef9471f,
    name: "pharmacies-dermocenters-our-dermocenters___cz"
  }, {
    path: "/lekarny/dermocentra/socialni-site",
    component: _74224362,
    name: "pharmacies-dermocenters-social-networks___cz"
  }, {
    path: "/",
    component: _6e2afbe7,
    name: "index___cz",
    children: []
  }, {
    path: "/lekarny/dermocentra/:dermocenter",
    component: _42d40a9f,
    name: "pharmacies-dermocenters-dermocenter___cz"
  }, {
    path: "/akcni-vyprodej/:b?",
    component: _1ca9310c,
    name: "akcni-vyprodej-b___cz",
    children: []
  }, {
    path: "/autori/:author",
    component: _3f61ff56,
    name: "authors-author___cz"
  }, {
    path: "/confirmation-email/:token",
    component: _0991542e,
    name: "confirmation-email-token___cz"
  }, {
    path: "/lekarny/:pharmacy",
    component: _18407904,
    name: "pharmacies-pharmacy___cz"
  }, {
    path: "/nabidka-1-1-zdarma/:b?",
    component: _0b4bd7d4,
    name: "nabidka-1-1-zdarma-b___cz",
    children: []
  }, {
    path: "/nabidka-3-za-cenu-2/:b?",
    component: _18847c2e,
    name: "nabidka-3-za-cenu-2-b___cz",
    children: []
  }, {
    path: "/nabidka-s-darkem/:b?",
    component: _bddaed32,
    name: "nabidka-s-darkem-b___cz",
    children: []
  }, {
    path: "/pokladna/:step",
    component: _254a01b7,
    alias: "/checkout/:step",
    name: "checkout-step___cz"
  }, {
    path: "/repay/:hash?",
    component: _040cbcae,
    name: "repay-hash___cz"
  }, {
    path: "/specialni-nabidky/:b?",
    component: _046bc39b,
    name: "specialni-nabidky-b___cz",
    children: []
  }, {
    path: "/tagy/:tag",
    component: _5092b942,
    name: "tags-tag___cz"
  }, {
    path: "/vsechny-akce-a-slevy/:b?",
    component: _6fe3e40e,
    name: "vsechny-akce-a-slevy-b___cz",
    children: []
  }, {
    path: "/zeptejte-se-lekarnika/:question",
    component: _229fd283,
    name: "askPharmacist-question___cz"
  }, {
    path: "/:a?/akcni-vyprodej",
    component: _07a5214e,
    name: "a-akcni-vyprodej___cz",
    children: []
  }, {
    path: "/:a?/nabidka-1-1-zdarma",
    component: _46152a2c,
    name: "a-nabidka-1-1-zdarma___cz",
    children: []
  }, {
    path: "/:a?/nabidka-3-za-cenu-2",
    component: _4b90fb4d,
    name: "a-nabidka-3-za-cenu-2___cz",
    children: []
  }, {
    path: "/:a?/nabidka-s-darkem",
    component: _6491e5fb,
    name: "a-nabidka-s-darkem___cz",
    children: []
  }, {
    path: "/:a?/specialni-nabidky",
    component: _3dc7fb02,
    name: "a-specialni-nabidky___cz",
    children: []
  }, {
    path: "/:a?/vsechny-akce-a-slevy",
    component: _09d83be6,
    name: "a-vsechny-akce-a-slevy___cz",
    children: []
  }, {
    path: "/:prefix?/promo",
    component: _7fe3d023,
    name: "promotion___cz"
  }, {
    path: "/:prefix?/vyhledavani",
    component: _be9fffc4,
    name: "search___cz"
  }, {
    path: "/:a?/akcni-vyprodej/:b?",
    component: _1d19a152,
    name: "a-akcni-vyprodej-b___cz",
    children: []
  }, {
    path: "/:a?/nabidka-1-1-zdarma/:b?",
    component: _2430bc24,
    name: "a-nabidka-1-1-zdarma-b___cz",
    children: []
  }, {
    path: "/:a?/nabidka-3-za-cenu-2/:b?",
    component: _71e1ef11,
    name: "a-nabidka-3-za-cenu-2-b___cz",
    children: []
  }, {
    path: "/:a?/nabidka-s-darkem/:b?",
    component: _ac4ca582,
    name: "a-nabidka-s-darkem-b___cz",
    children: []
  }, {
    path: "/:a?/specialni-nabidky/:b?",
    component: _14891ac3,
    name: "a-specialni-nabidky-b___cz",
    children: []
  }, {
    path: "/:a?/vsechny-akce-a-slevy/:b?",
    component: _0e6f85d1,
    name: "a-vsechny-akce-a-slevy-b___cz",
    children: []
  }, {
    path: "/*",
    component: _242aae65,
    name: "all___cz"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
